import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { SnackbarProvider } from "notistack";
import React from "react";
import { SWRConfig } from "swr";

import { LanguageProvider } from "./LanguageProvider";
import { ThemeProvider } from "./ThemeProvider";

import { fetcher } from "clients/axios";
import { AuthProvider } from "contexts/AuthContext";
import { FiltersProvider } from "contexts/FilterContext";
import { InstanceProvider } from "contexts/InstanceContext";
import { SettingsProvider } from "contexts/SettingsContext";

export const Providers: React.FC = ({ children }) => (
  <SnackbarProvider dense maxSnack={3}>
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <AuthProvider>
            <InstanceProvider>
              <FiltersProvider>
                <SWRConfig value={{ fetcher }}>
                  <ThemeProvider>
                    <LanguageProvider>{children}</LanguageProvider>
                  </ThemeProvider>
                </SWRConfig>
              </FiltersProvider>
            </InstanceProvider>
          </AuthProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </SnackbarProvider>
);
